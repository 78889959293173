import axios from 'axios'
import { IAsset, IAssetDiscovery, RecordType } from 'blue-project-types'
import { API_INVENTORY_URL } from '../../../config/urls'
import {
	IAccessToken,
	IAssetVerificationType,
	ICreateUpdatePerson,
	IFetchPeopleQueryData,
	ISaveAssetsRequest,
	ISavePeopleRequest,
} from './types'

export const SYNC_PEOPLE_WITH_GOOGLE_KEY = 'syncPeopleWithGoogle'
export const syncPeopleWithGoogle = async (data: IAccessToken) => {
	const response = await axios.post(`${API_INVENTORY_URL}/people/sync-google`, data)

	return response.data
}

export const SYNC_PEOPLE_WITH_MICROSOFT_KEY = 'syncPeopleWithMicrosoft'
export const syncPeopleWithMicrosoft = async (data: IAccessToken) => {
	const response = await axios.post(`${API_INVENTORY_URL}/people/sync-microsoft`, data)

	return response.data
}

export const SAVE_PEOPLE_KEY = 'savePeople'
export const savePeople = async (data: ISavePeopleRequest) => {
	const response = await axios.post(`${API_INVENTORY_URL}/people/batch`, data, {
		params: {
			deleteNotInList: data.deleteNotInList,
		},
	})

	return response.data
}

export const FETCH_PEOPLE_KEY = 'fetchPeople'
export const fetchPeople = async (params?: IFetchPeopleQueryData) => {
	const response = await axios.get(`${API_INVENTORY_URL}/people`, { params })

	return response.data
}

export const COUNT_PEOPLE_KEY = 'countPeople'
export const countPeople = async () => {
	const response = await axios.get(`${API_INVENTORY_URL}/people/count`)

	return response.data
}

export const UPDATE_PERSON_KEY = 'updatePerson'
export const updatePerson = async ({ id, ...data }: ICreateUpdatePerson) => {
	const response = await axios.put(`${API_INVENTORY_URL}/people/${id}`, data)

	return response.data
}

export const REMOVE_PERSON_KEY = 'removePerson'
export const removePerson = async (id: string) => {
	const response = await axios.delete(`${API_INVENTORY_URL}/people/${id}`)

	return response.data
}

export const FETCH_PEOPLE_TAGS_KEY = 'fetchPeopleTags'
export const fetchPeopleTags = async () => {
	const response = await axios.get(`${API_INVENTORY_URL}/people/tags/all`)

	return response.data
}

export const SAVE_ASSETS_KEY = 'saveAssets'
export const saveAssets = async (data: ISaveAssetsRequest) => {
	const response = await axios.post(`${API_INVENTORY_URL}/assets/create/batch`, data)

	return response.data
}

export const FETCH_ASSETS_KEY = 'fetchAssets'
export const fetchAssets = async () => {
	const response = await axios.get(`${API_INVENTORY_URL}/assets/list`)

	return response.data
}

export const FETCH_ASSET_KEY = 'fetchAssets'
export const fetchAsset = async (assetId: string) => {
	const response = await axios.get(`${API_INVENTORY_URL}/assets/${assetId}`)

	return response.data
}

export const FETCH_ASSET_VERIFICATION_KEY = 'fetchAssetVerification'
export const fetchAssetVerification = async (assetId: string) => {
	const response = await axios.get(`${API_INVENTORY_URL}/assets/${assetId}/verification`)

	return response.data
}

export const ASSET_VERIFY_DOMAIN_KEY = 'assetVerifyDomain'
export const assetVerifyDomain = async (assetId: string, data: IAssetVerificationType) => {
	const response = await axios.post(
		`${API_INVENTORY_URL}/assets/${assetId}/verification/verify`,
		data,
	)

	return response.data
}

export const FETCH_DNS_RECORDS_KEY = 'fetchDnsRecords'
export const fetchDnsRecords = async (assetId: string) => {
	const response = await axios.get(`${API_INVENTORY_URL}/assets/${assetId}/dns-records`)

	return response.data
}

export const FETCH_DNS_RECORD_HISTORY_KEY = 'fetchDnsRecordHistory'
export const fetchDnsRecordHistory = async ({
	assetId,
	recordType,
}: {
	assetId: string
	recordType: RecordType
}) => {
	const response = await axios.get(
		`${API_INVENTORY_URL}/assets/${assetId}/dns-records/${recordType}/history`,
	)

	return response.data
}

export const FETCH_IP_INFO_KEY = 'fetchIpInfo'
export const fetchIpInfo = async (assetId: string) => {
	const response = await axios.get(`${API_INVENTORY_URL}/assets/${assetId}/ip-info`)

	return response.data
}

export const FETCH_IP_HISTORY_INFO_KEY = 'fetchIpHistoryInfo'
export const fetchIpHistoryInfo = async (assetId: string) => {
	const response = await axios.get(`${API_INVENTORY_URL}/assets/${assetId}/ip-info/history`)

	return response.data
}

export const REMOVE_ASSET_KEY = 'removeAsset'
export const removeAsset = async (id: string) => {
	const response = await axios.delete(`${API_INVENTORY_URL}/assets/${id}`)

	return response.data
}

export const FETCH_ASSET_DISCOVERY_FOR_ASSET_KEY = 'fetchAssetDiscoveryForAsset'
export const fetchAssetDiscoveryForAsset = async (assetId: string): Promise<IAssetDiscovery> => {
	const response = await axios.get(`${API_INVENTORY_URL}/assets/${assetId}/discoveries`)

	return response.data
}

export const UPDATE_ASSET_MONITORING_KEY = 'updateAssetMonitoring'
export const updateAssetMonitoring = async (data: Partial<IAsset>) => {
	const response = await axios.put(`${API_INVENTORY_URL}/assets/${data.id}/monitoring`, {
		monitored: data.monitored,
	})

	return response.data
}

export const FETCH_PEOPLE_SUMMARY_KEY = 'fetchPeopleSummary'
export const fetchPeopleSummary = async () => {
	const response = await axios.get(`${API_INVENTORY_URL}/people/tenants/overview`)

	return response.data
}
