import { IPersonPointsProperty } from 'blue-project-types'

export const calculateTotalPointsEffect = (properties: IPersonPointsProperty[]) =>
	properties.reduce((acc, curr) => acc + curr.pointsEffect, 0)

export const calculateScoreChange = (lastMonthsScore: number, currentScore: number) => {
	if (lastMonthsScore === undefined || lastMonthsScore === null) {
		return undefined
	}

	const change = currentScore - lastMonthsScore
	return Math.round(change)
}
