import { Progress } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import React from 'react'

import { ScoreCategory } from 'blue-project-types'
import { ReactComponent as ArrowDownIcon } from '../../../../../assets/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from '../../../../../assets/arrow-up.svg'
import { Tag, Text } from '../../../../../components'
import { Colors, ScoreColors } from '../../../../../styles/colors'
import { SPACES } from '../../../../../styles/spaces'

interface IProps {
	score: number
	scoreCategory: ScoreCategory
	scoreChange: number | undefined
}

const SCORE_COLOR = {
	'0%': '#FF1E2B',
	'26%': '#FB7604',
	'51%': '#FFBA00',
	'76%': '#EEFF2C',
	'96%': '#25C7B6',
}

const PEOPLE_SCORE_BASE_TRANSLATE = 'inventory.people.score.tenantOverview'

const ScoreValue: React.FC<IProps> = ({ score, scoreCategory, scoreChange }) => {
	const { t } = useTranslation()

	return (
		<SectionWrapper>
			<ScoreWrapper>
				<Text
					color={Colors.Black}
					zeroMargin
					style={{ fontSize: 24, fontWeight: 500, lineHeight: '24px' }}
				>
					{t(`${PEOPLE_SCORE_BASE_TRANSLATE}.scoreTitle`)}
				</Text>
				<Tag color={ScoreColors[scoreCategory]} style={{ margin: '0px' }}>
					{t(`common.score.${scoreCategory}`)}
				</Tag>
				{scoreChange && (
					<div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
						{scoreChange > 0 ? (
							<ArrowUpIcon width={24} height={24} />
						) : (
							<ArrowDownIcon width={24} height={24} />
						)}
						<Text zeroMargin color={scoreChange > 0 ? Colors.GreenNew : Colors.RedNew}>
							{t(`${PEOPLE_SCORE_BASE_TRANSLATE}.scoreVsLastMonth`, {
								scoreChange,
							})}
						</Text>
					</div>
				)}
			</ScoreWrapper>
			<ProgressWrapper>
				<Label className="start">0</Label>
				<Label className="end">100</Label>
				<Progress
					type="dashboard"
					percent={score}
					format={(percent) => (
						<>
							<Text
								color={ScoreColors[scoreCategory]}
								zeroMargin
								style={{ fontSize: 64, fontWeight: 600 }}
							>
								{percent}
							</Text>
							<StyledText color={Colors.Black}>{t(`common.score.${scoreCategory}`)}</StyledText>
						</>
					)}
					strokeColor={SCORE_COLOR}
					strokeWidth={8}
					width={225}
				/>
			</ProgressWrapper>
			<Description>{t(`${PEOPLE_SCORE_BASE_TRANSLATE}.scoreDescription`)}</Description>
		</SectionWrapper>
	)
}

const ScoreWrapper = styled.div`
	display: flex;
	gap: ${SPACES.SMALL};
	align-items: center;
	padding-bottom: ${SPACES.DEFAULT};
`

const ProgressWrapper = styled.div`
	position: relative;
	display: flex;
	justify-self: center;
	width: 225px;
	height: 225px;
	padding-block: ;
`
const StyledText = styled(Text)`
	font-size: ${SPACES.SMALL};
	padding-top: ${SPACES.EXTRA_SMALL};
	font-weight: 500;
`
const Description = styled(Text)`
	font-size: ${SPACES.SMALL};
	padding-top: ${SPACES.DEFAULT};
	margin: 0;
	font-weight: 400;
	color: ${Colors.Gray};
`

const SectionWrapper = styled.div`
	max-width: 40%;
	padding: ${SPACES.DEFAULT};
	border-right: 0.5px solid #e1e5eb;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`
const Label = styled.div`
	position: absolute;
	font-size: 14px;
	font-weight: 400;
	color: ${Colors.Gray};
	&.start {
		left: 50px;
		bottom: -10px;
	}
	&.end {
		right: 50px;
		bottom: -10px;
	}
`
export default ScoreValue
