import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ScoreCategory } from 'blue-project-types'

import { Text } from '../../../../../components'
import { Colors } from '../../../../../styles/colors'
import { SPACES } from '../../../../../styles/spaces'

interface IProps {
	scoreCategory: ScoreCategory
}

const PEOPLE_SCORE_BASE_TRANSLATE = 'inventory.people.score.tenantOverview'

const ScoreSummaryText: React.FC<IProps> = ({ scoreCategory }) => {
	const { t } = useTranslation()

	return (
		<TextWrapper>
			<Text zeroMargin color={Colors.Dark}>
				{t(`${PEOPLE_SCORE_BASE_TRANSLATE}.scoreText.${scoreCategory}`)}
			</Text>
			<Text zeroMargin color={Colors.Dark}>
				{t(`${PEOPLE_SCORE_BASE_TRANSLATE}.summaryText.${scoreCategory}`)}
			</Text>
		</TextWrapper>
	)
}

const TextWrapper = styled.div`
	font-size: 24px;
	display: flex;
	flex-direction: column;
	font-weight: 500;
	max-width: 680px;
	gap: ${SPACES.DEFAULT};
	padding-left: ${SPACES.DEFAULT};
`

export default ScoreSummaryText
