import {
	IGetPeopleAwarenessOverviewResponse,
	IGetPeopleSimulationsOverviewResponse,
} from 'blue-project-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Title from '../../../components/Title'
import { Colors } from '../../../styles/colors'
import { SPACES } from '../../../styles/spaces'
import AwarenessSummary from './training-campaigns-overview/AwarenessSummary'
import SimulationsResult from './training-campaigns-overview/SimulationsResult'

interface IProps {
	peoplePhishingOverview: IGetPeopleSimulationsOverviewResponse
	peopleAwarenessOverview: IGetPeopleAwarenessOverviewResponse
}

const TRANSLATE_CAMPAIGNS_OVERVIEW_BASE_KEY = 'pdfExports.SeifScoreExport.trainingCampaignsOverview'

const CampaignsOverview: React.FC<IProps> = ({
	peoplePhishingOverview,
	peopleAwarenessOverview,
}) => {
	const { t } = useTranslation()

	return (
		<Wrapper>
			<Title color={Colors.Dark} style={{ marginBottom: SPACES.DEFAULT }}>
				{t(`${TRANSLATE_CAMPAIGNS_OVERVIEW_BASE_KEY}.title`)}
			</Title>
			<AwarenessSummary peopleAwarenessOverview={peopleAwarenessOverview} />
			<SimulationsResult
				totalAttacks={peoplePhishingOverview?.allSimulationsTemplatesCount}
				opened={peoplePhishingOverview?.openedByTargetsSimulationsCount}
				clicked={peoplePhishingOverview?.clickedByTargetsSimulationsCount}
				failed={peoplePhishingOverview?.failedByTargetsCount}
			/>
		</Wrapper>
	)
}

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	padding-block: ${SPACES.EXTRA_LARGE};
`

export default CampaignsOverview
