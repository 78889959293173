import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { Colors } from '../styles/colors'
import { SPACES } from '../styles/spaces'
import { ScoreCategory } from 'blue-project-types'

export const ZeroPaddingPageWrapper = styled.div`
	margin: -${SPACES.DEFAULT} -${SPACES.LARGE};
`

// Styling Ant design components

export const largeSteps = css`
	.ant-steps-item-icon {
		width: 48px;
		height: 48px;
		line-height: 48px;
		border: none;
		margin-right: ${SPACES.SMALL};
	}
`

export const steps = css`
	.ant-steps-item-title::after {
		content: none;
	}
	.ant-steps-item-content {
		.ant-steps-item-title {
			font-size: 14px;
			font-weight: 500;
			line-height: normal;
		}
		.ant-steps-item-description {
			font-size: 12px;
		}
	}
	.ant-steps-item-wait {
		.ant-steps-item-icon {
			.ant-steps-icon {
				color: ${Colors.Blue};
			}
		}
	}
	.ant-steps-item-active {
		.ant-steps-item-content {
			.ant-steps-item-title {
				color: ${Colors.Blue};
			}
		}
	}
`

export const StyledList = styled.ul`
	margin: 60px 0 0 0;
	padding: 0;
	list-style: none;
	li {
		position: relative;
		padding-left: ${SPACES.LARGE};
		margin-bottom: ${SPACES.DEFAULT};
		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: ${SPACES.XXS};
			width: ${SPACES.SMALL};
			height: ${SPACES.SMALL};
			border-radius: 100%;
			background-color: ${rgba(`${Colors.Blue}`, 0.4)};
		}
		&:after {
			width: ${SPACES.XXS};
			height: ${SPACES.XXS};
			top: 10px;
			left: 6px;
			background-color: ${Colors.Blue};
		}
	}
`

export const veryLowRiskGradient = css`
	background: #e8474f;
	background-image: linear-gradient(221deg, #c30078, #e8474f 51%, #f46b2a);
`

export const lowRiskGradient = css`
	background: #ff7600;
	background-image: linear-gradient(41deg, #fabe55, #ff7600 47%, #ed644a 100%);
`

export const mediumRiskGradient = css`
	background: #f7c644;
	background-image: linear-gradient(41deg, #76eaba, #f7c644 47%, #ffb371);
`

export const highRiskGradient = css`
	background: #4cd3bf;
	background-image: linear-gradient(41deg, #76eaba, #4cd3bf 47%, #00ffe3);
`

export const veryHighRiskGradient = css`
	background: #4cd3bf;
	background-image: linear-gradient(41deg, #1581ff, #4cd3bf 51%, #00ffe3);
`

const MAP_RISK_TO_GRADIENT = {
	[ScoreCategory.VeryLow]: veryLowRiskGradient,
	[ScoreCategory.Low]: lowRiskGradient,
	[ScoreCategory.Medium]: mediumRiskGradient,
	[ScoreCategory.High]: highRiskGradient,
	[ScoreCategory.VeryHigh]: veryHighRiskGradient,
}

export const RiskGradientWrapper = styled.div<{ risk: string }>`
	${({ risk }) => MAP_RISK_TO_GRADIENT[risk]};
	height: 100%;
	padding: ${SPACES.DEFAULT};
	border-radius: 8px;
`
