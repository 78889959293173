import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { IGetPeopleAwarenessOverviewResponse } from 'blue-project-types'
import { useMemo } from 'react'

import { ReactComponent as CourseRateIcon } from '../../../../assets/courses-completion-rate.svg'
import { ReactComponent as NanoRateIcon } from '../../../../assets/nano-rate.svg'
import { Colors } from '../../../../styles/colors'
import { SPACES } from '../../../../styles/spaces'
import TitleSubtitleWrapper from '../../../inventory/components/common/TitleSubtitleWrapper'
import AwarenessRate from './AwarenessRate'

interface IProps {
	peopleAwarenessOverview: IGetPeopleAwarenessOverviewResponse
}

const TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY = 'pdfExports.SeifScoreExport.trainingCampaignsOverview'

const AwarenessSummary: React.FC<IProps> = ({ peopleAwarenessOverview }) => {
	const { t } = useTranslation()

	const { nanoEngagementRate, allAnsweredCount, courseCompletionRate, nanoStats, courseStats } =
		useMemo(() => {
			const nanoStats = peopleAwarenessOverview?.nanoLearningStatistics || {}
			const courseStats = peopleAwarenessOverview?.coursesStatistics || {}
			return {
				nanoEngagementRate: nanoStats.nanoLessonsSentCount
					? Math.round((nanoStats.nanoEngagementCount / nanoStats.nanoLessonsSentCount) * 100)
					: 0,

				allAnsweredCount: nanoStats.nanoLessonsSentCount
					? nanoStats.answeredCorrectlyCount + nanoStats.incorrectAnswerCount
					: 0,

				courseCompletionRate: courseStats.totalLearnersCount
					? Math.round((courseStats.completedLearnersCount / courseStats.totalLearnersCount) * 100)
					: 0,
				nanoStats,
				courseStats,
			}
		}, [peopleAwarenessOverview])

	return (
		<Wrapper>
			<SectionWrapper
				style={{
					borderRight: `0.5px solid ${Colors.GrayHover}`,
				}}
			>
				<TitleSubtitleWrapper
					title={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.courses.title`)}
					subtitle={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.courses.subtitle`)}
				/>
				<StatisticWrapper>
					<AwarenessRate
						rate={`${courseCompletionRate}%`}
						title={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.courses.completionRate.title`)}
						subtitle={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.courses.completionRate.subtitle`)}
						icon={<CourseRateIcon />}
					/>
					<AwarenessRate
						rate={`${courseStats.passedLearnersCount}/${courseStats.totalLearnersCount}`}
						title={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.courses.passingRate.title`)}
						subtitle={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.courses.passingRate.subtitle`)}
						icon={<CourseRateIcon />}
					/>
				</StatisticWrapper>
			</SectionWrapper>
			<SectionWrapper>
				<TitleSubtitleWrapper
					title={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.nanoQuiz.title`)}
					subtitle={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.nanoQuiz.subtitle`)}
				/>
				<StatisticWrapper>
					<AwarenessRate
						rate={`${nanoEngagementRate}%`}
						title={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.nanoQuiz.engagementRate.title`)}
						subtitle={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.nanoQuiz.engagementRate.subtitle`)}
						icon={<NanoRateIcon />}
					/>
					<AwarenessRate
						rate={`${nanoStats.answeredCorrectlyCount}/${allAnsweredCount}`}
						title={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.nanoQuiz.passingRate.title`)}
						subtitle={t(`${TRANSLATE_CAMPAIGN_OVERVIEW_BASE_KEY}.nanoQuiz.passingRate.subtitle`)}
						icon={<NanoRateIcon />}
					/>
				</StatisticWrapper>
			</SectionWrapper>
		</Wrapper>
	)
}

const Wrapper = styled.section`
	display: flex;
	border-top-left-radius: ${SPACES.DEFAULT};
	border-top-right-radius: ${SPACES.DEFAULT};
	background: ${Colors.White};
`
const StatisticWrapper = styled.div`
	display: flex;
	align-items: flex-top;
	gap: ${SPACES.EXTRA_LARGE};
	padding-top: ${SPACES.DEFAULT};
`

const SectionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	padding: 40px 48px;
`
export default AwarenessSummary
