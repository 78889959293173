const en = {
	overview: {
		overviewTitle: 'Overview',
		title: 'Continuous Security Overview',
		header: {
			riskScore: 'Risk score',
		},
		noSeifScoreAlert: {
			title: 'Seif Score Not Available',
			subtitle:
				'"The seif score for this organization hasn’t been calculated or there was an error retrieving it. Please try again later or contact support for assistance.".',
		},
		activeOrg: {
			title: "Organization's Analytics",
			organization: 'Organization',
			organizations: 'Organizations',
			organizationDescription: 'being continuously monitored',
			people: "People in {{tenantName}}'s org",
			peopleDescription: 'People in active organization',
			assignments: 'Active Assignments',
			assignment: 'Active Assignment',
			assignmentsDescription: 'Active ongoing assignment',
			assets: "Assets in {{tenantName}}'s org",
			asset: "Asset in {{tenantName}}'s org",
			assetsDescription: 'Assets in active organization',
			learners: 'People learning',
			learnersDescription: 'learners who started the course',
		},
		activeAssignments: {
			title: "Assignment's Analytics",
			completionRate: 'Course completion rate',
			completionRateDescription: 'All learner progress',
			learnerCompletion: 'learner completion',
			learnerCompletionDescription:
				"learners who completed the course compared to those who didn't yet",
			passingRate: 'passing rate',
			passingRateDescription: 'learners who passed compared to whose who failed',
			averageScore: 'Average score',
			averageScoreDescription: 'for learners that completed the course',
		},
		learningAssignments: {
			title: 'Learning Assignments',
		},
		vulnerabilities: {
			title: "Assets's Analytics",
		},
		quickLinks: {
			title: 'Quick links',
			addPeople: '+ Add People',
			addNewOrganization: '+ Add New Organization',
			createNewAssignment: '+ Create Awareness Assignment',
			addNewUsers: '+ Add New Users',
		},
		noAssets: {
			title: 'There are no assets within the organization',
			subtitle: 'Please add assets to the organization to be able to see assets overview',
		},
	},
}

export default en
