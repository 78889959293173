import { Language, ScoreCategory } from 'blue-project-types'
import { PdfDownloadStatus } from '../context/pdf-exports/context'
import admin from '../features/admin/translate/sr'
import auth from '../features/auth/translate/sr'
import awareness from '../features/awareness/translate/sr'
import dashboard from '../features/dashboard/translate/sr'
import extensionReports from '../features/extension-reports/translate/sr'
import groupAdministrations from '../features/group-admin/translate/sr'
import inventory from '../features/inventory/translate/sr'
import leaks from '../features/leaks/translate/sr'
import library from '../features/library/translate/sr'
import nanoLearn from '../features/nano-learn/translate/sr'
import notifications from '../features/notifications/translate/sr'
import organizations from '../features/organizations/translate/sr'
import pdfExports from '../features/pdf-exports/translate/sr'
import publicPages from '../features/public/translate/sr'
import settings from '../features/settings/translate/sr'
import simulations from '../features/simulations/translate/sr'
import vulnerabilities from '../features/vulnerabilities/translate/sr'
import waf from '../features/waf/translate/sr'
import layout from '../layout/translate/sr'

const common = {
	confirm: 'Potvrdi',
	cancel: 'Otkaži',
	save: 'Sačuvaj',
	back: 'Nazad',
	continue: 'Nastavi',
	finish: 'Završi',
	next: 'Sledeći',
	skip: 'Preskoči',
	apply: 'Primeni',
	remove: 'Ukloni',
	restore: 'Povrati',
	saveChanges: 'Sačuvaj Izmene',
	createPage: 'Kreiraj Stranu',
	add: 'Dodaj',
	clear: 'Očisti',
	edit: 'Izmeni',
	previewHTML: 'Pogledaj HTML',
	copy: 'Kopiraj',
	seeMore: 'Vidite više',
	numbers: {
		2: 'dva',
		100: 'sto',
		1: 'jedan',
		8: 'osam',
	},
	more: 'više',
	time: {
		today: 'Danas',
	},
	validation: {
		errors: {
			required: 'Potrebno',
			requiredWithField: '{{field}} is obavezno',
			moreThen0: 'Mora biti pozitivno',
			between0And10: 'Između 0 i 10',
			assetRequired: 'Potreban je najmanje jedan resurs',
			textRequired: 'Potreban je tekst',
			min: '{{field}} mora imati najmanje {{numberOfCharacters}} karaktera',
			max: '{{field}} mora imati maksimum {{numberOfCharacters}} karaktera',
			email: 'Nevalidna mejl adresa',
			password: 'Mora sadržati najmanje 6 karaktera i najmanje 1 broj',
			arrayMin: 'Najmanje {{number}} izabrana {{item}}',
			numberMax: '{{field}} moze biti najvise {{maxNumber}}',
			emailDuplicate: 'Duplikat adresa e-pošte nije dozvoljen',
			validUrl: 'Molimo vas da unesete ispravan URL.',
		},
	},
	errors: {
		generic: 'Došlo je do greške!',
	},
	pdfDownload: {
		error: 'Dogodila se greska',
		[PdfDownloadStatus.Downloading]: 'Preuzmi',
		[PdfDownloadStatus.PendingToBeCreated]: 'Kreiranje PDF-a',
		[PdfDownloadStatus.ReadyToBeDownloaded]: 'Kreiranje PDF-a',
	},
	messages: {
		noOptions: 'Ne postoje opcije',
		personDeleted: 'Osoba je uklonjena iz organizacije!',
	},
	imageUpload: {
		addImages: 'Dodaj slike',
		dragAndDropText:
			'Dodajte sliku sliku <1>Prevucite-i-otpustite ili kliknite da dodate sa računara</1>',
	},
	language: {
		[Language.En]: 'Engleski',
		[Language.Sr]: 'Srpski',
		[Language.De]: 'Nemački',
		[Language.Es]: 'Španski',
		[Language.Sl]: 'Slovenački',
	},

	scoreStatus: {
		[ScoreCategory.VeryLow]: 'Veoma Nizak',
		[ScoreCategory.Low]: 'Nizak',
		[ScoreCategory.Medium]: 'Srednji',
		[ScoreCategory.High]: 'Visok',
		[ScoreCategory.VeryHigh]: 'Veoma Visok',
	},
	score: {
		[ScoreCategory.VeryLow]: 'Veoma nizak nivo',
		[ScoreCategory.Low]: 'Nizak nivo',
		[ScoreCategory.Medium]: 'Srednji nivo',
		[ScoreCategory.High]: 'Visok nivo',
		[ScoreCategory.VeryHigh]: 'Veoma visok nivo',
	},
	components: {
		transferList: {
			item: '1 stavka',
			items: '{{number}} stavki(e)',
			searchPlaceholder: 'Pretraži stavke',
		},
	},
	noDataMessage: 'Nema podataka',
}

const sr = {
	common,
	vulnerabilities,
	awareness,
	simulations,
	inventory,
	layout,
	organizations,
	settings,
	dashboard,
	admin,
	auth,
	waf,
	notifications,
	groupAdministrations,
	nanoLearn,
	library,
	leaks,
	extensionReports,
	publicPages,
	pdfExports,
}

export default sr
