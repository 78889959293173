import { IPeopleOverviewResponse, IPerson, getScoreCategory } from 'blue-project-types'
import { useMemo } from 'react'
import styled from 'styled-components'

import { Colors } from '../../../../../styles/colors'
import { SPACES } from '../../../../../styles/spaces'
import { calculateScoreChange } from '../../../utils/score'
import ScoreSummary from './ScoreSummary'
import ScoreValue from './ScoreValue'

interface IProps {
	peopleSummary: IPeopleOverviewResponse
	people: IPerson[]
}

const PeopleScore: React.FC<IProps> = ({ peopleSummary, people }) => {
	const scoreChange = useMemo(() => {
		if (!peopleSummary) {
			return
		}
		return calculateScoreChange(peopleSummary.lastMonthScore || 0, peopleSummary.score)
	}, [peopleSummary?.score, peopleSummary?.lastMonthScore])

	const scoreCategory = useMemo(() => {
		if (!peopleSummary) return null
		return getScoreCategory(peopleSummary.score)
	}, [peopleSummary])

	return (
		<Wrapper>
			<ScoreValue
				score={peopleSummary.score}
				scoreCategory={scoreCategory!}
				scoreChange={scoreChange}
			/>
			<ScoreSummary scoreCategory={scoreCategory!} />
		</Wrapper>
	)
}

const Wrapper = styled.section`
	display: flex;
	gap: ${SPACES.DEFAULT};
	background: ${Colors.White};
	border-top-left-radius: ${SPACES.DEFAULT};
	border-top-right-radius: ${SPACES.DEFAULT};
`

export default PeopleScore
