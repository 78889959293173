import { ScoreCategory } from 'blue-project-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Text } from '../../../../../components'
import { Colors } from '../../../../../styles/colors'
import { SPACES } from '../../../../../styles/spaces'
import ScoreSummaryIcon from './ScoreSummaryIcon'
import ScoreSummaryText from './ScoreSummaryText'

const PEOPLE_SCORE_BASE_TRANSLATE = 'inventory.people.score.tenantOverview'

interface IProps {
	scoreCategory: ScoreCategory
}

const ScoreSummary: React.FC<IProps> = ({ scoreCategory }) => {
	const { t } = useTranslation()

	return (
		<Wrapper>
			<Text color={Colors.Black} style={{ fontSize: 24, fontWeight: 500 }}>
				{t(`${PEOPLE_SCORE_BASE_TRANSLATE}.scoreSummary`)}
			</Text>
			<DescriptionWrapper>
				<ScoreSummaryIcon scoreCategory={scoreCategory} />
				<ScoreSummaryText scoreCategory={scoreCategory} />
			</DescriptionWrapper>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: ${SPACES.DEFAULT};
`

const DescriptionWrapper = styled.div`
	display: flex;
	flex-grow: 2;
	justify-content: start;
	align-items: center;
	padding: ${SPACES.LARGE} 0;
`

export default ScoreSummary
