import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Text from '../../../../../components/Text'
import { Colors } from '../../../../../styles/colors'
import { SPACES } from '../../../../../styles/spaces'
import { Description } from '../StyledComponents'
import { ReactComponent as CoursesCountIcon } from '../../../../../assets/course-count.svg'
import { ReactComponent as CampaignsCountIcon } from '../../../../../assets/campaigns-count.svg'
import { ReactComponent as SimulationsCountIcon } from '../../../../../assets/simulation-count.svg'
import { ReactComponent as LeaksCountIcon } from '../../../../../assets/leaks-count.svg'
import ActionComponent from './ActionComponent'

interface IProps {
	launchedCoursesCount: number
	launchedSimulationsCount: number
	launchedNanoCampaignsCount: number
	solvedLeaksCount: number
}

const TRANSLATE_KEY_ACTION_BASE_KEY = 'pdfExports.SeifScoreExport.keyAction'

const KeyActionTaken: React.FC<IProps> = ({
	launchedCoursesCount,
	launchedSimulationsCount,
	launchedNanoCampaignsCount,
	solvedLeaksCount,
}) => {
	const { t } = useTranslation()

	return (
		<Wrapper>
			<Text color={Colors.Black} zeroMargin style={{ fontSize: 24, fontWeight: 500 }}>
				{t(`${TRANSLATE_KEY_ACTION_BASE_KEY}.title`)}
			</Text>
			<Description style={{ width: '50%' }}>
				{t(`${TRANSLATE_KEY_ACTION_BASE_KEY}.subtitle`)}
			</Description>
			<ActionWrapper>
				<ActionComponent
					icon={<CoursesCountIcon />}
					count={launchedCoursesCount}
					title={t(`${TRANSLATE_KEY_ACTION_BASE_KEY}.courses.title`)}
					description={t(`${TRANSLATE_KEY_ACTION_BASE_KEY}.courses.description`)}
				/>
				<ActionComponent
					icon={<SimulationsCountIcon />}
					count={launchedSimulationsCount}
					title={t(`${TRANSLATE_KEY_ACTION_BASE_KEY}.simulations.title`)}
					description={t(`${TRANSLATE_KEY_ACTION_BASE_KEY}.simulations.description`)}
				/>
				<ActionComponent
					icon={<CampaignsCountIcon />}
					count={launchedNanoCampaignsCount}
					title={t(`${TRANSLATE_KEY_ACTION_BASE_KEY}.nanoCampaigns.title`)}
					description={t(`${TRANSLATE_KEY_ACTION_BASE_KEY}.nanoCampaigns.description`)}
				/>
				<ActionComponent
					icon={<LeaksCountIcon />}
					count={solvedLeaksCount}
					title={t(`${TRANSLATE_KEY_ACTION_BASE_KEY}.leaks.title`)}
					description={t(`${TRANSLATE_KEY_ACTION_BASE_KEY}.leaks.description`)}
				/>
			</ActionWrapper>
		</Wrapper>
	)
}

const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	background: ${Colors.White};
	padding: ${SPACES.EXTRA_LARGE} 48px;
`
const ActionWrapper = styled.section`
	display: flex;
	justify-content: space-between;
`

export default KeyActionTaken
