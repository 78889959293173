import { ScoreCategory } from 'blue-project-types'

import {
	ShieldErrorIcon,
	ShieldSuccessIcon,
	ShieldWarningIcon,
} from '../../../../../components/Icons'
import { ScoreColors } from '../../../../../styles/colors'
import { SPACES } from '../../../../../styles/spaces'

interface IProps {
	scoreCategory: ScoreCategory
}

const MAP_SCORE_TO_ICON = {
	[ScoreCategory.VeryLow]: ShieldErrorIcon,
	[ScoreCategory.Low]: ShieldErrorIcon,
	[ScoreCategory.Medium]: ShieldWarningIcon,
	[ScoreCategory.High]: ShieldSuccessIcon,
	[ScoreCategory.VeryHigh]: ShieldSuccessIcon,
}

const ScoreSummaryIcon: React.FC<IProps> = ({ scoreCategory }) => {
	const Component = MAP_SCORE_TO_ICON[scoreCategory]

	return (
		<div style={{ marginTop: 6, marginRight: SPACES.SMALL }}>
			<Component style={{ fontSize: 48, color: ScoreColors[scoreCategory] }} />
		</div>
	)
}

export default ScoreSummaryIcon
